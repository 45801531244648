import makeStyles from '@material-ui/core/styles/makeStyles'

export type PaymentFormClasses = ReturnType<typeof usePaymentFormStyles>

const usePaymentFormStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  wrapper: {
    'height': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'maxWidth': '1000px',
    'margin': '0 auto',

    '& > div': {
      width: '100%',
    },
  },
  progress: {
    'position': 'absolute',
    'left': 0,
    'top': 0,
    'height': 4,
    'width': '100%',
    'display': 'flex',
    '& .step': {
      'background': 'rgba(0, 0, 0, 0.12)',
      'marginRight': 2,
      'flex': '1 1 auto',
      'transition': 'background 300ms ease-in 0s',
      '&:last-child': {
        marginRight: 0,
      },
      '&.active': {
        background: theme.palette.primary.main,
      },
    },
  },
  returnLink: {
    'marginTop': theme.spacing(2),
    'textAlign': 'center',
    '& a': {
      'display': 'inline-flex',
      'alignItemns': 'center',
      '& svg': {
        height: 15,
        marginTop: 1,
        marginRight: -5,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  merchantLogo: {
    height: 42,
    [theme.breakpoints.down(400)]: {
      height: 36,
    },
  },
  orderInfo: {
    'textAlign': 'right',
    'lineHeight': '150%',
    '& .id': {
      letterSpacing: '0.25px',
      marginBottom: theme.spacing(0.5),
    },
    '& .amount': {
      fontSize: 24,
      letterSpacing: '0.18px',
      color: '#000',
      [theme.breakpoints.down(400)]: {
        fontSize: 18,
      },
    },
  },
  orderInfoCenter: {
    'textAlign': 'center',
    'lineHeight': '150%',
    '& .id': {
      letterSpacing: '0.25px',
      marginBottom: '12px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .amount': {
      fontSize: theme.spacing(4.5),
      letterSpacing: '0.18px',
      color: '#000',
      lineHeight: '100%',
    },
  },
  blockTitle: {
    fontSize: 12,
    lineHeight: '16px',
    paddingBottom: theme.spacing(1.5),
    textTransform: 'uppercase',
    letterSpacing: '0.04px',
    color: '#8B959E',
    '&.center': {
      textAlign: 'center',
    },
  },
  bold: {
    fontWeight: 700,
    fontSize: 16,
  },
  formGroup: {
    paddingBottom: theme.spacing(3),
  },
  orderDetailsRow: {
    'marginBottom': theme.spacing(6 / 8),
    'lineHeight': '150%',
    'letterSpacing': '0.25px',
    'display': 'flex',
    'alignItems': 'baseline',
    'justifyContent': 'space-between',
    '& .dTitle': {
      flex: '0 0 99px',
      whiteSpace: 'nowrap',
    },
    '& > *': {
      wordBreak: 'break-all',
      whiteSpace: 'break-word',
    },
    '&.payout': {
      color: 'rgba(0,0,0,0.87)'
    },
  },
  addressMobile: {
    'display': 'flex',
    'alignItems': 'center',
    '& button': {
      margin: 0,
      padding: 0,
    },
  },
  delim: {
    margin: '26px -40px 32px',
    height: 1,
    background: theme.palette.divider,
    '&.payout': {
      margin: '32px 0 20px',
    },
    [theme.breakpoints.down(350)]: {
      margin: '26px -40px 26px',
    },
  },
  frame: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    background: '#fcfcfc',
    zIndex: 10000,
    // width: '100%',
    // minHeight: '500px',
    border: 'none',
  },
  field: {
    'paddingBottom': 0,
    '& .MuiFilledInput-root': {
      height: 48,
    },
    '& .MuiFilledInput-underline': {
      '&.Mui-error': {
        backgroundImage:
          'linear-gradient(#F5F6F7 46px, #e53935 2px) !important',
      },
      '&.Mui-focused': {
        backgroundImage: `linear-gradient(#F5F6F7 46px, ${theme.palette.primary.main} 2px) !important`,
      },
    },
    '& .MuiFilledInput-input': {
      'paddingTop': 22,
      'paddingBottom': 6,
      '&#cardholder': {
        textTransform: 'uppercase',
      },
    },
    '& .MuiInput-input': {
      paddingTop: 14,
      paddingBottom: 14,
    },
    '& .MuiInputLabel-filled': {
      'transform': 'translate(12px, 16px) scale(1)',
      '&.Mui-focused, &.MuiInputLabel-shrink': {
        transform: 'translate(12px, 6px) scale(0.75)',
      },
    },
    '& .MuiFormHelperText-root': {
      top: 46,
    },
    '&.cvv': {
      '& input': {
        paddingTop: 24,
        paddingBottom: 12,
        fontFamily: 'dots',
        fontSize: 16,
        letterSpacing: '-5px',
      },
    },
    '&.select': {
      '& .MuiFilledInput-input': {
        cursor: 'default',
      },
    },
  },
  row: {
    'marginBottom': theme.spacing(2),
    'display': 'flex',
    'height': 48,
    'gap': '16px',
    '& > *': {
      flex: '1 1 auto',
    },
    '& .helper': {
      fontSize: 12,
      alignSelf: 'center',
      flex: '0 0 49%',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  switch: {
    'display': 'flex',
    'justifyContent': 'flex-end',
    'alignItems': 'center',
    'marginBottom': theme.spacing(2),
    [theme.breakpoints.down(350)]: {
      marginBottom: '0px',
    },
    '& .MuiSwitch-root': {
      'width': 32,
      'height': 16,
      'padding': 0,
      'marginLeft': theme.spacing(1),
      '& .MuiSwitch-thumb': {
        width: 14,
        height: 14,
        boxShadow: 'none',
        backgroundColor: '#fff',
      },
      '& .MuiSwitch-switchBase': {
        'padding': 1,
        '&.Mui-checked': {
          'transform': 'translateX(16px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
          },
        },
      },
      '& .MuiSwitch-track': {
        borderRadius: 8,
      },
    },
    '& .MuiSwitch-colorPrimary': {
      color: '#fff',
    },
  },
  termsBlock: {},
  footer: {
    'marginTop': theme.spacing(5),
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    '& .protection': {
      'display': 'flex',
      'alignItems': 'center',
      '& > *': {
        display: 'block',
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
  dialog: {
    background: '#fff',
    width: 444,
    padding: '27px 16px 80px',
    borderRadius: 8,
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    minHeight: 300,
    [theme.breakpoints.down(460)]: {
      width: 'calc(100vw - 16px)',
    },
  },
  dialogHeader: {
    position: 'absolute',
    left: 16,
    top: 27,
    right: 16,
    height: 94,
    background: '#fff',
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '150%',
    marginBottom: 20,
    [theme.breakpoints.down(350)]: {
      fontSize: 16,
    },
  },
  dialogList: {
    'position': 'absolute',
    'paddingTop': 13,
    'paddingBottom': 50,
    'top': 120,
    'left': 16,
    'right': 16,
    'bottom': 80,
    'overflowY': 'auto',
    '&.apms': {
      bottom: 18,
      paddingBottom: 0,
    },
  },
  dialogFooter: {
    'height': 64,
    'position': 'absolute',
    'background': '#fff',
    'left': 0,
    'right': 0,
    'bottom': 0,
    'padding': '0 16px 16px',
    'display': 'flex',
    'alignItems': 'flex-end',
    '& > *': {
      'flex': '1 1 auto',
      '&:first-child': {
        marginRight: 12,
      },
    },
  },
  payButtonWrapper: {
    paddingTop: theme.spacing(4),
  },
  apmInfoWrapper: {
    marginTop: -10,
    marginBottom: 25,
  },
  apmSelectSubInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down(350)]: {
      marginBottom: 10,
    },
  },
  apmSelectReminder: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: '#8B959E',
  },
  apmSelectionInfo: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'left',
    color: '#8B959E',
    [theme.breakpoints.down('xs')]: {
      width: 95,
    },
    [theme.breakpoints.down(350)]: {
      width: '100%',
    },
  },
  apmDescriptionShowButton: {
    'fontSize': 14,
    'fontWeight': 500,
    'lineHeight': '20px',
    'letterSpacing': '0.15000000596046448px',
    'textAlign': 'right',
    'borderBottom': '1px dashed #8B959E',
    'color': '#8B959E',

    '&:hover': {
      cursor: 'pointer',
      color: '#2F66E3',
      borderBottom: '1px dashed #2F66E3',
    },
  },

  /** */
  apmDescriptionFade: {
    // position: 'absolute',
    // width: '100%',
    // height: '100%',
    // background: 'rgba(0, 0, 0, 0.6)',
    // left: 0,
    // bottom: -2000,
    // paddingTop: 32,
    // transition: 'bottom 0.5s ease-in-out',
    // '&.show': {
    //   bottom: '0',
    // }
  },
  apmDescriptionWrapper: {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px 8px 0 0',
  },
  apmDescriptionTopWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  apmDescriptionTop: {
    height: 60,
  },
  topLine: {
    width: 60,
    height: 5,
    borderRadius: 5,
    backgroundColor: '#B2B9C8',
    position: 'absolute',
    left: 'calc(50% - 30px)',
    top: 5,
    opacity: 0.5,
  },
  closeButton: {
    'position': 'absolute',
    'top': 15,
    'right': 15,
    'backgroundColor': '#B2B9C8',
    'borderRadius': 20,
    'height': 32,
    'width': 32,
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'opacity': 0.5,
    'cursor': 'pointer',

    '&:hover': {
      backgroundColor: '#B2B9C8',
      opacity: 1,
    },
  },
  apmDescription: {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    padding: '0 32px 0 32px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px 0 20px',
    },
  },
  apmDescriptionHeader: {
    fontFamily: '"Inter"',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '30px',
    letterSpacing: '-0.03em',
    textAlign: 'left',
    color: '#011627',
    marginBottom: 20,
  },
  apmDescriptionSubHeader: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.03em',
    textAlign: 'left',
    paddingBottom: 20,
    borderBottom: '1px solid #B2B9C8',
    marginBottom: 20,
  },
  apmDescriptionTooltip: {
    'display': 'flex',
    'alignItems': 'center',
    'width': '100%',
    'left': 0,
    'top': 131,
    'borderRadius': 8,
    'padding': '15px 20px 15px 20px',
    'backgroundColor': '#F8F8FA',

    'fontSize': 14,
    'fontWeight': 500,
    'lineHeight': '20px',
    'letterSpacing': 0.15000000596046448,
    'textAlign': 'left',
    'marginBottom': 30,
    'color': '#011627',

    '& svg': {
      marginRight: 20,
      width: 54,
      [theme.breakpoints.down('xs')]: {
        width: 96,
      },
    },
  },
  apmPaymentOptionsList: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'paddingBottom': 32,

    '&>div': {
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        paddingBottom: 30,
      },
    },
  },
  apmPaymentOption: {
    fontFamily: '"Inter"',
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '-0.03em',
    textAlign: 'left',
  },
}))

export default usePaymentFormStyles
