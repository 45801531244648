import React from 'react'

import clsx from 'clsx'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'

import useGlobalStyles from '@/hooks/useGlobalStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 420,
    maxWidth: '100%',
    padding: '24px',
    background: '#fff',
    borderRadius: 8,
  },
  icon: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  content: {
    'padding': 0,
    'textAlign': 'center',
    'marginBottom': theme.spacing(3),
    '&:first-child': {
      padding: 0,
    },
  },
  actions: {
    display: 'flex',
    gap: '12px',
    padding: 0,
    flexDirection: 'column',
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  buttonCancel: {
    'backgroundColor': 'rgba(125, 147, 168, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(125, 147, 168, 0.12)',
    },
  },
  modal: {
    padding: '0px 20px',
  },
}))

interface IAlertModalProps {
  open: boolean
  icon: React.ReactNode
  title: string
  subtitle: string
  buttonCancelText: string
  buttonConfirmText: string
  children: React.ReactNode

  onCancel: () => void
  onConfirm: () => void
}

function AlertModal({
  open,
  icon,
  title,
  children,
  subtitle,
  buttonCancelText,
  buttonConfirmText,

  onCancel,
  onConfirm,
}: IAlertModalProps) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  return (
    <Dialog open={open} className={classes.modal}>
      <div className={classes.root}>
        <DialogContent className={classes.content}>
          <div className={classes.icon}>{icon}</div>
          <Typography
            variant="h6"
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Typography variant="body2" className={classes.subtitle}>
            {subtitle}
          </Typography>
          {children}
        </DialogContent>
        <DialogActions className={classes.actions} disableSpacing>
          <Button
            className={clsx(globalClasses.button, 'small')}
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            {buttonConfirmText}
          </Button>
          <Button
            className={clsx(
              globalClasses.button,
              classes.buttonCancel,
              'small'
            )}
            onClick={onCancel}
          >
            {buttonCancelText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
export default AlertModal
