import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePopupSelectStyles } from './ResidenceStyles'
import ResidenceSelect from './ResidenceSelect'

import { IAutocompleteOption } from '../../../components/Form/AutocompleteField'
import usePaymentFormStyles from '../usePaymentFormStyles'
import countryNames from '../../../helpers/country'
import { ICountry, ICountryState } from '../../../types/country'
import { ILangType } from '../../../locales'
import BillingFields from '../../Exchange/forms/BillingForm'
import { trackOnrampLogin, trackUsFlowInfo } from '@/helpers/analytics'
import { IOrder } from '@/api/order'
import { getResidenceFromStorage } from '@/helpers/getResidenceFromStorage'

interface IProps {
  setHasBilling(value: boolean): void
  billingAddress: any
  lang: ILangType
  countriesData: ICountry[]
  statesData: ICountryState[]
  countryCode?: string
  detectedCountry?: string
  setCountryCode: (code: string) => void
  setStateCode: (code: string) => void
  countryError?: string
  btnDisabled: boolean
  setBtnDisabled(value: boolean): void
  setBillingData: any
  cityError?: string
  setCityError: any
  zipcodeError?: string
  setZipcodeError: any
  streetAddressError?: string
  setStreetAddressError: any
  order?: IOrder
  stateCode?: string
  firstNameError?: string
  setFirstNameError(err?: string): void
  lastNameError?: string
  placeOfBirthError?: string
  setPlaceOfBirthError(err?: string): void
  setDateOfBirthError(err?: string): void
  setLastNameError(err?: string): void
  dateOfBirthError?: string
}

interface ICountriesMemo {
  countries: IAutocompleteOption[]
  selectedCountry: ICountry | null
}

export default function ResidenceField({
  setHasBilling,
  billingAddress,
  lang,
  countriesData,
  statesData,
  countryCode,
  detectedCountry,
  setStateCode,
  setCountryCode,
  countryError,
  setBtnDisabled,
  btnDisabled,
  setBillingData,
  cityError,
  setCityError,
  setStreetAddressError,
  setZipcodeError,
  streetAddressError,
  zipcodeError,
  order,
  stateCode,
  dateOfBirthError,
  firstNameError,
  setFirstNameError,
  lastNameError,
  placeOfBirthError,
  setLastNameError,
  setPlaceOfBirthError,
  setDateOfBirthError,
}: IProps) {
  const { t } = useTranslation()
  const classes = usePaymentFormStyles()
  const localClasses = usePopupSelectStyles()
  const storageResidence = getResidenceFromStorage()

  const [checkedCountry, setCheckedCountry] =
    useState<IAutocompleteOption | null>(null)

  const [checkedState, setCheckedState] = useState<IAutocompleteOption | null>(
    null
  )

  const { countries, selectedCountry } = useMemo<ICountriesMemo>(() => {
    let selectedCountry: ICountry | null = null
    if (!countriesData.length) {
      return { countries: [], selectedCountry }
    }
    const countryList = countryNames.getNames(lang)
    const options: IAutocompleteOption[] = []
    countriesData.forEach((c) => {
      const option: IAutocompleteOption = {
        value: c.code,
        label: countryList[c.code],
        name: c.name,
        icon: c.code,
      }

      if (storageResidence && c.code === storageResidence?.code) {
        setCountryCode(storageResidence.code)
        selectedCountry = c
      }

      if (c.code === checkedCountry?.value) {
        selectedCountry = c
      }

      if (c.code === detectedCountry) {
        options.unshift(option)
      } else {
        options.push(option)
      }
    })
    return { countries: options, selectedCountry }
  }, [countryCode, checkedCountry, countriesData, lang])

  const countryStates = useMemo<IAutocompleteOption[]>(() => {
    if (!selectedCountry || !selectedCountry.useState) {
      return []
    }
    return statesData.reduce((result, state) => {
      if (state.country === selectedCountry.code) {
        result.push({
          value: state.code,
          label: state.name,
          name: state.name,
        })
      }
      return result
    }, [] as IAutocompleteOption[])
  }, [selectedCountry?.name, selectedCountry?.useState])

  useEffect(() => {
    if (!selectedCountry?.useState) {
      return
    }

    if (billingAddress.city && billingAddress.city.length < 3) {
      setCityError(t('validation.minLengthErr', { length: 3 }))
    } else if (
      billingAddress.city &&
      !/^[a-zA-Z- ]+$/.test(billingAddress.city)
    ) {
      setCityError(t('validation.Must be letters'))
    } else if (billingAddress.city) {
      setCityError('')
    }

    if (billingAddress.zipCode && !/^\d{5}$/.test(billingAddress.zipCode)) {
      setZipcodeError(t('validation.lengthErrParam', { length: 5 }))
    } else if (billingAddress.zipCode) {
      setZipcodeError('')
    }
  }, [billingAddress.city, billingAddress.zipCode])

  useEffect(() => {
    const hasNoCountry = !selectedCountry
    const mustHaveState = Boolean(selectedCountry?.useState && !checkedState)
    const hasBillingError = Boolean(
      selectedCountry?.useState &&
        (cityError || zipcodeError || streetAddressError)
    )
    const emptyBilling = Boolean(
      selectedCountry?.useState &&
        (!billingAddress.city ||
          !billingAddress.zipCode ||
          !billingAddress.address)
    )

    setBtnDisabled(
      hasNoCountry || mustHaveState || hasBillingError || emptyBilling
    )

    setHasBilling(Boolean(selectedCountry?.useState))
  }, [
    selectedCountry,
    checkedState,
    cityError,
    zipcodeError,
    streetAddressError,
    billingAddress,
  ])

  useEffect(() => {
    trackOnrampLogin('view_cor_screen', order, 'onramp_personal_info')
  }, [])

  useEffect(() => {
    if (
      !cityError &&
      zipcodeError === '' &&
      !streetAddressError &&
      selectedCountry?.useState
    ) {
      trackUsFlowInfo(
        'add_billing_address',
        order,
        checkedCountry?.label,
        checkedState?.label,
        'onramp_personal_info'
      )
    }
  }, [cityError, streetAddressError, zipcodeError, selectedCountry?.useState])

  return (
    <div className={localClasses.root}>
      {!storageResidence && (
        <ResidenceSelect
          order={order}
          code={countryCode}
          checked={checkedCountry}
          setChecked={setCheckedCountry}
          title={t('Country Of Residence')}
          placeholder={t('place holder Country Of Residence')}
          name="country"
          fieldId="country-of-residence-select"
          setCode={setCountryCode}
          detected={detectedCountry}
          options={countries}
          classes={classes}
          searchTitle={t('Select your country of residence')}
          errorText={countryError}
        />
      )}
      {selectedCountry?.useState && (
        <ResidenceSelect
          checkedCountry={checkedCountry}
          order={order}
          code={stateCode}
          checked={checkedState}
          setChecked={setCheckedState}
          title={t('Select state')}
          placeholder={t('placeholder select state')}
          name="state"
          fieldId="us-flow-state-select"
          setCode={setStateCode}
          options={countryStates}
          classes={classes}
          searchTitle={t('placeholder select state')}
          errorText={''}
        />
      )}
      {selectedCountry?.useState && checkedState && (
        <BillingFields
          classes={classes}
          countries={countriesData}
          setBillingData={setBillingData}
          setZipcodeError={setZipcodeError}
          zipcodeError={zipcodeError}
          setStreetAddressError={setStreetAddressError}
          streetAddressError={streetAddressError}
          setCityError={setCityError}
          cityError={cityError}
          dateOfBirthError={dateOfBirthError}
          firstNameError={firstNameError}
          setFirstNameError={setFirstNameError}
          lastNameError={lastNameError}
          placeOfBirthError={placeOfBirthError}
          setPlaceOfBirthError={setPlaceOfBirthError}
          setDateOfBirthError={setDateOfBirthError}
          setLastNameError={setLastNameError}
        />
      )}
    </div>
  )
}
