export const EMPTY_BILLING_ADDRESS = {
  city: '',
  address: '',
  zipCode: '',
  lastName: '',
  firstName: '',
  middleName: '',
  dateOfBirth: '',
  placeOfBirth: '',
}

export const DEFAULT_BILLING_COUNTRY = {
  name: '',
  code: '',
  useState: true,
  supported: true,
}
