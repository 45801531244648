import React from 'react'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'

import { IOrder } from '@/api/order'
import { useTypedSelector } from '@/hooks/useTypedSelector'

const useStyles = makeStyles((theme) => ({
  orderInfo: {
    'lineHeight': '150%',
    'textTransform': 'uppercase',
    'color': '#7F8AAC',
    '& .text': {
      letterSpacing: '0.03em',
      marginBottom: '2px',
      fontSize: '0.625rem',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    '& .id': {
      letterSpacing: '0.07em',
      marginBottom: theme.spacing(3),
      fontWeight: 600,
      [theme.breakpoints.down('xs')]: {
        marginBottom: '12px',
      },
    },
    '& .amount': {
      fontWeight: 800,
      lineHeight: 1,
      fontSize: '2.25rem',
      letterSpacing: '0.01em',
      color: '#000',
    },
    '& .currency': {
      fontWeight: 300,
      lineHeight: 1,
      fontSize: '2.25rem',
      letterSpacing: '0.01em',
      color: '#000',
    },
  },
  amountWrapper: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  apmImage: {
    objectFit: 'contain',
    maxHeight: 55,
    maxWidth: 94,
  },
  orderImage: {
    width: '120px',
    height: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '10px 13px',
    backgroundColor: '#F8F8FA',
  },
  orderImageWrapper: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))

interface ICCWChainValleyProps {
  order: IOrder
  showOrderAmount: boolean
}

function CCWChainValley({ order, showOrderAmount }: ICCWChainValleyProps) {
  const { t } = useTranslation()

  const logo = useTypedSelector((state) => state.appData.apm.logo)
  const convertedApm = useTypedSelector((state) => state.appData.convertedApm)

  const classes = useStyles()

  return (
    <>
      <div className={classes.orderInfo}>
        <Typography variant="body2" className="text" component="div">
          {t('Exchange order')}
        </Typography>
        <Typography variant="body2" className="id" component="div">
          №{order.id}
        </Typography>
        {showOrderAmount && (
          <Typography
            translate="no"
            component="div"
            className={classes.amountWrapper}
          >
            {convertedApm?.apmAmount && (
              <>
                <Typography variant="body2" className="amount" component="span">
                  {convertedApm.apmAmount}
                </Typography>{' '}
                <Typography
                  variant="body2"
                  className="currency"
                  component="span"
                >
                  {order.paymentCurrency}
                </Typography>
              </>
            )}
          </Typography>
        )}
      </div>
      <div className={classes.orderImageWrapper}>
        {logo && (
          <div className={classes.orderImage}>
            <img
              src={logo ?? '/img/apm/static/NoLogo.svg'}
              alt="Apm"
              className={classes.apmImage}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default CCWChainValley
