import React from 'react'

import Link from '@material-ui/core/Link'

import ShortString from '../UI/ShortString'

interface IShortenedLink {
  href: string
  chars: number
}

function ShortenedLink({ href, chars }: IShortenedLink) {
  return (
    <Link
      href={href}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
      target="_blank"
    >
      <ShortString chars={chars} text={href} />
    </Link>
  )
}

export default ShortenedLink
