import { useEffect, useState } from 'react'

import { QR_LIFETIME_MINUTES } from '@/constants'

import { getTimerMinutes, getTimerSeconds } from '../utils'

interface IUseTimerProps {
  qrCode: string
}

export default function useTimer({ qrCode }: IUseTimerProps) {
  const [totalSeconds, setTotalSeconds] = useState(QR_LIFETIME_MINUTES * 60)

  useEffect(() => {
    if (!qrCode) {
      return
    }

    const timer = setInterval(() => {
      setTotalSeconds((seconds) => Math.max(seconds - 1, 0))
    }, 1000)

    return () => clearInterval(timer)
  }, [qrCode])

  const timerMinutes = getTimerMinutes(totalSeconds)
  const timerSeconds = getTimerSeconds(totalSeconds)

  return { timerMinutes, timerSeconds, totalSeconds }
}
