import { DependencyList, useEffect, useRef } from 'react'

import { IOrder, OrderStatus, useOrder } from '@/api/order'

interface IUseCheckOrderStatusProps {
  order: IOrder
  deps?: DependencyList
  callback?: () => void
  nextStep: (step: string) => void
}

export default function useCheckOrderStatus({
  deps = [],
  order,
  nextStep,
  callback,
}: IUseCheckOrderStatusProps) {
  const orderStatusCheckTimeout = useRef<NodeJS.Timeout | null>(null)

  const [{ data: orderInfo }, loadingOrder, loadOrder] = useOrder()

  useEffect(() => {
    return () => {
      if (orderStatusCheckTimeout.current) {
        clearTimeout(orderStatusCheckTimeout.current)
      }
    }
  }, deps)

  const checkOrderStatus = () => {
    loadOrder({ id: order.id })
      .then((res) => {
        if (res?.id) {
          if (
            [
              OrderStatus.SUSPICIOUS,
              OrderStatus.EXECUTED,
              OrderStatus.SUCCESS,
            ].includes(res.status)
          ) {
            callback?.()
            nextStep('success')
            return
          }

          if (res.status === OrderStatus.ERROR) {
            callback?.()
            nextStep('error')
            return
          }

          orderStatusCheckTimeout.current = setTimeout(checkOrderStatus, 10000)
        } else {
          orderStatusCheckTimeout.current = setTimeout(checkOrderStatus, 10000)
        }
      })
      .catch(
        () =>
          (orderStatusCheckTimeout.current = setTimeout(
            checkOrderStatus,
            10000
          ))
      )
  }

  return checkOrderStatus
}
