import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Box, makeStyles, Typography } from '@material-ui/core'

import AlertModal from '../AlertModal'

const useStyles = makeStyles((theme) => ({
  walletWrapper: {
    paddingTop: theme.spacing(2),
  },
  wallet: {
    fontSize: '1rem',
    padding: theme.spacing(2),
    backgroundColor: '#F5F6F8',
    overflowWrap: 'break-word',
  },
}))

export interface IWalletOwnerModalHandles {
  show: () => void
  hide: () => void
}

interface IWalletOwnerModalProps {
  wallet?: string

  onPressCancel: () => void
  onPressConfirm: () => void
}

const WalletOwnerModal = forwardRef(function WalletOwnerModal(
  { onPressCancel, onPressConfirm, wallet = '' }: IWalletOwnerModalProps,
  forwardedRef
) {
  const { t } = useTranslation()

  const classes = useStyles()

  const [openModal, setOpenModal] = useState(false)

  useImperativeHandle(forwardedRef, () => ({
    show: () => setOpenModal(true),
    hide: () => setOpenModal(false),
  }))

  return (
    <AlertModal
      open={openModal}
      title={t('walletOwner.title')}
      subtitle={t('walletOwner.subtitle')}
      buttonCancelText={t('walletOwner.buttonCancel')}
      buttonConfirmText={t('walletOwner.buttonConfirm')}
      onCancel={onPressCancel}
      onConfirm={onPressConfirm}
      icon={
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM22 30H18V18H22V30ZM22 14H18V10H22V14Z"
            fill="#F2C94C"
          />
        </svg>
      }
    >
      <Box className={classes.walletWrapper}>
        <Typography className={classes.wallet}>{wallet}</Typography>
      </Box>
    </AlertModal>
  )
})

export default WalletOwnerModal
