import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import { makeStyles, Typography } from '@material-ui/core'

import AlertModal from '../AlertModal'

const useStyles = makeStyles(() => ({
  description: {
    'textAlign': 'left',
    '& ul': {
      margin: 0,
      paddingLeft: '20px',
    },
  },
}))

export interface IRiskDisclosureModalHandles {
  show: () => void
  hide: () => void
}

interface IRiskDisclosureModalProps {
  onPressCancel: () => void
  onPressConfirm: () => void
}

const RiskDisclosureModal = forwardRef(function RiskDisclosureModal(
  { onPressCancel, onPressConfirm }: IRiskDisclosureModalProps,
  forwardedRef
) {
  const { t } = useTranslation()

  const classes = useStyles()

  const [openModal, setOpenModal] = useState(false)

  useImperativeHandle(forwardedRef, () => ({
    show: () => setOpenModal(true),
    hide: () => setOpenModal(false),
  }))

  return (
    <AlertModal
      open={openModal}
      title={t('riskDisclosure.title')}
      subtitle={t('riskDisclosure.subtitle')}
      buttonConfirmText={t('riskDisclosure.buttonConfirm')}
      buttonCancelText={t('riskDisclosure.buttonCancel')}
      onCancel={onPressCancel}
      onConfirm={onPressConfirm}
      icon={
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM22 30H18V18H22V30ZM22 14H18V10H22V14Z"
            fill="#F2C94C"
          />
        </svg>
      }
    >
      <Typography
        variant="body2"
        component="div"
        className={clsx('small', classes.description)}
        dangerouslySetInnerHTML={{
          __html: t('riskDisclosure.description'),
        }}
      />
    </AlertModal>
  )
})

export default RiskDisclosureModal
