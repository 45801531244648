import { IOrder } from '@/api/order'
import { IAPM } from '@/api/settings'

import apmMap from './apmMap'
import { VIEW_FOR_APMS, isSf } from './constants'

const getIsShowRevolutLogo = (apm: IAPM | undefined) => {
  return (
    (apm?.name && apm.name.toLowerCase().includes('revolut')) ||
    (apm?.id && ['REV_FT', 'REV_TR'].includes(apm.id))
  )
}

const getIsShowOBILogo = (apmId: string) => {
  return [
    'OBI',
    'OBI_FT',
    'OBI_TR',
    'WS_FT',
    'WS_TR',
    'N26_FT',
    'N26_TR',
  ].includes(apmId)
}

export function getApmView(step: string | undefined, order?: IOrder) {
  if (
    order?.apm &&
    VIEW_FOR_APMS.includes(order?.apm) &&
    ((isSf && (step === 'card' || step === 'transaction')) ||
      (order?.sf && step === 'card'))
  ) {
    return true
  }
  return false
}

export function getApmImageSrc(apm: IAPM | undefined) {
  if (getIsShowRevolutLogo(apm)) {
    return '/img/apm/revolut.png'
  }

  if (apm?.logo) {
    return apm.logo
  }

  if (apm?.id && apm.id !== 'CARD') {
    if (getIsShowOBILogo(apm.id)) {
      return `/img/apm/newOBI_v2.svg`
    }

    if (apm.id === 'BLIK') {
      return `/img/apm/newBLIK_v2.svg`
    }
    return `/img/apm/new${apm.id}.svg`
  }

  return apmMap['NoLogo'].logo
}
