import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { ISettlement, SettlementStatus } from '@/types/settlements'
import DateView from '@/components/DateView'
import ShortenedLink from '@/components/ShortenedLink'

import SettlementDetails from './Details'
import SettlementStatusView from './SettlementStatusView'
import useGlobalStyles from '../../../hooks/useGlobalStyles'

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(1),
  },
}))

interface IMobileSettlementsList {
  settlements: ISettlement[]
}

export function MobileSettlementsList({ settlements }: IMobileSettlementsList) {
  const { t } = useTranslation()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [settlementDetails, setSettlementDetails] = useState({})

  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  const openDetails = (settlement: ISettlement) => {
    setSettlementDetails(settlement)
    setIsOpenModal(true)
  }

  return (
    <>
      <div className={globalClasses.mobileShow}>
        <Table className={globalClasses.tableSimple}>
          <TableBody>
            {settlements.map((settlement) => (
              <TableRow
                key={settlement.id}
                onClick={() => openDetails(settlement)}
              >
                <TableCell>
                  <Typography
                    variant="body2"
                    className={classes.row}
                    component="div"
                  >
                    <strong>{settlement.id}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.row}
                    component="div"
                  >
                    <SettlementStatusView status={settlement.status} />
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    className={clsx(
                      settlement?.settlementAmount && classes.row
                    )}
                  >
                    {t('settlements.requested')}:{' '}
                    <DateView value={settlement.createdAt} />
                  </Typography>
                  {settlement.status === SettlementStatus.SETTLED && (
                    <Typography
                      variant="body2"
                      className={classes.row}
                      component="div"
                    >
                      {t('settlements.hash')}:{' '}
                      {settlement?.blockchainTxId && (
                        <ShortenedLink
                          href={settlement.blockchainTxId}
                          chars={20}
                        />
                      )}
                      {!settlement?.blockchainTxId && '—'}
                    </Typography>
                  )}
                  {settlement?.settlementAmount && (
                    <Typography variant="body2" component="div">
                      {t('settlements.settlementAmount')}:{' '}
                      {settlement.settlementAmount}{' '}
                      {settlement.settlementCurrency}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    {t('settlements.reqAmount')}:
                  </Typography>
                  <Typography variant="body2" component="div">
                    {Object.entries(settlement.operations).map(([id, data]) => (
                      <div key={id}>
                        {Object.entries(data).map(([currency, amount]) => (
                          <Typography
                            key={currency}
                            component="h1"
                            variant="body2"
                          >
                            {amount} {currency}
                          </Typography>
                        ))}
                      </div>
                    ))}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SettlementDetails
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        settlement={settlementDetails}
      />
    </>
  )
}

export default MobileSettlementsList
