import React, { useEffect, useState, useRef } from 'react'

import * as Sentry from '@sentry/react'

import { useTranslation } from 'react-i18next'

import snsWebSdk from '@sumsub/websdk'

import { ICustomer } from '@/types'

import { IOrder } from '../../../../api/order'
import Loader from '../../../../components/Loader'
import sentryConfig from '../../../../config/sentry.json'
import ServerMessage from '../../../../components/UI/ServerMessage'
import getBrowserLanguage from '../../../../helpers/tempSumSubLangRemaper'

interface IProps {
  order: IOrder
  authToken: string
  customer: ICustomer
  setIsNeedConfirmation: (confirm: boolean) => void
  setKycWalletToken: (token: string) => void
}

const browserLang = getBrowserLanguage()

const sentryEnabled = process.env.REACT_APP_SENTRY_URL

export default function SumSubWalletVerification({
  order,
  customer,
  authToken,
  setKycWalletToken,
  setIsNeedConfirmation,
}: IProps) {
  const { t } = useTranslation()

  const container = useRef<HTMLDivElement>(null)
  const [isSumSubError, setIsSumSubError] = useState(false)

  useEffect(() => {
    if (!container.current) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      window.scrollTo(0, 0)
    })

    resizeObserver.observe(container.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    if (authToken && authToken.length) {
      const snsWebSdkInstance = snsWebSdk
        .init(authToken, async () => {
          try {
            return
          } catch (e) {
            if (sentryEnabled) {
              Sentry.captureException(
                {
                  tokenRefreshError: JSON.stringify(e),
                },
                { tags: { section: 'SumSub' } }
              )
            }
          }
        })
        .withConf({
          lang: browserLang,
          email: customer.email,
          phone: customer.phone,
          i18n: {},
        })
        .on('onError', (e: any) => {
          if (sentryEnabled) {
            Sentry.captureException(e, { tags: { section: 'SumSub' } })
          }
          setIsSumSubError(true)
        })
        .onMessage((type: any, payload: any) => {
          if (
            sentryEnabled &&
            sentryConfig.sendSumsubInitializationInfo &&
            type === 'idCheck.applicantReviewComplete'
          ) {
            Sentry.captureException(
              { ...payload, description: 'wallet verification' },
              { tags: { section: 'SumSub' } }
            )
          }

          if (type === 'idCheck.applicantReviewComplete') {
            setIsNeedConfirmation(true)
            setKycWalletToken('')
          }
        })

      snsWebSdkInstance.build().launch('#sumsub-websdk-container')
    }
  }, [authToken, order])

  return (
    <div>
      <Loader loading={false} />
      {isSumSubError && (
        <ServerMessage type="error" text={t('somethingWentWrong')} />
      )}
      <div id="sumsub-websdk-container" ref={container} />
    </div>
  )
}
