import { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { EMPTY_BILLING_ADDRESS } from '@/constants'
import { ICustomer, ICustomerBillingAddress } from '@/types/customer'

import {
  useCustomerStateSet,
  useCustomerCountrySet,
  useCustomerBillingAddressSet,
} from '../../api/customer'

interface IUseResidenceParams {
  billing: boolean
  customer: ICustomer
  billingSaveCb(res: any): void
}

interface IUseResidence {
  billingAddress: ICustomerBillingAddress
  setBillingAddress(value: ICustomerBillingAddress): void
  saveBilling(data: any): Promise<any>
  saveCountry(data: {
    country: string
  }): Promise<{ data: { success: boolean } }>
  saveState(data: { state: string }): Promise<{ data: { success: boolean } }>
  residenceSaving: boolean
  validateCountry(formValid: boolean, errorFocused: boolean): [boolean, boolean]
  validateBillingAddress(formValid: boolean, showErrors?: boolean): boolean

  countryError?: string
  setCountryError(err?: string): void
  cityError?: string
  setCityError(err?: string): void
  streetAddressError?: string
  setStreetAddressError(err?: string): void
  zipcodeError?: string
  setZipcodeError(err?: string): void
  dateOfBirthError?: string
  firstNameError?: string
  setFirstNameError(err?: string): void
  lastNameError?: string
  placeOfBirthError?: string
  setPlaceOfBirthError(err?: string): void
  setDateOfBirthError(err?: string): void
  setLastNameError(err?: string): void
}

export default function useResidence({
  billing,
  customer,
  billingSaveCb,
}: IUseResidenceParams): IUseResidence {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [billingAddress, setBillingAddress] = useState<ICustomerBillingAddress>(
    EMPTY_BILLING_ADDRESS
  )

  useEffect(() => {
    if (customer?.billingAddress) {
      setBillingAddress({
        ...EMPTY_BILLING_ADDRESS,
        ...customer.billingAddress,
      })
    }
  }, [customer?.billingAddress])

  const [countryError, setCountryError] = useState<string | undefined>()
  const [cityError, setCityError] = useState<string | undefined>()
  const [placeOfBirthError, setPlaceOfBirthError] = useState<
    string | undefined
  >()
  const [dateOfBirthError, setDateOfBirthError] = useState<string | undefined>()
  const [firstNameError, setFirstNameError] = useState<string | undefined>()
  const [lastNameError, setLastNameError] = useState<string | undefined>()
  const [streetAddressError, setStreetAddressError] = useState<
    string | undefined
  >()
  const [zipcodeError, setZipcodeError] = useState<string | undefined>()

  const [saveBilling, savingBilling] =
    useCustomerBillingAddressSet(billingSaveCb)

  const [saveCountry, countrySaving] = useCustomerCountrySet()
  const [saveState, stateSaving] = useCustomerStateSet()

  useEffect(() => {
    if (countryError) {
      setCountryError(t('validation.Required field'))
    }
    if (cityError) {
      setCityError(t('validation.Required field'))
    }
    if (zipcodeError) {
      setZipcodeError(t('validation.Required field'))
    }
    if (streetAddressError) {
      setStreetAddressError(t('validation.Required field'))
    }
  }, [language])

  function validateCountry(
    formValid: boolean,
    errorFocused: boolean
  ): [boolean, boolean] {
    let valid = formValid
    const focus = errorFocused
    if (!customer.country) {
      setCountryError(t('validation.Required field'))
      valid = false
    }

    return [valid, focus]
  }

  function validateBillingAddress(
    formValid: boolean,
    showErrors = true
  ): boolean {
    let valid = formValid
    if (billing) {
      if (!billingAddress.city || !billingAddress.city.length) {
        if (showErrors) setCityError(t('validation.Required field'))
        valid = false
      }
      if (!billingAddress.zipCode || !billingAddress.zipCode.length) {
        if (showErrors) setZipcodeError(t('validation.Required field'))
        valid = false
      }
      if (!billingAddress.address || !billingAddress.address.length) {
        if (showErrors) setStreetAddressError(t('validation.Required field'))
        valid = false
      }
      if (!billingAddress.firstName || !billingAddress.firstName.length) {
        if (showErrors) setFirstNameError(t('validation.Required field'))
        valid = false
      }
      if (!billingAddress.lastName || !billingAddress.lastName.length) {
        if (showErrors) setLastNameError(t('validation.Required field'))
        valid = false
      }
      if (!billingAddress.placeOfBirth || !billingAddress.placeOfBirth.length) {
        if (showErrors) setPlaceOfBirthError(t('validation.Required field'))
        valid = false
      }
      if (!billingAddress.dateOfBirth) {
        if (showErrors) setDateOfBirthError(t('validation.Required field'))
        valid = false
      }
    }
    return valid
  }

  return {
    billingAddress,
    setBillingAddress,
    saveBilling,
    saveCountry,
    saveState,
    residenceSaving: savingBilling || countrySaving || stateSaving,
    validateCountry,
    validateBillingAddress,
    cityError,
    setCityError,
    countryError,
    setCountryError,
    streetAddressError,
    setStreetAddressError,
    dateOfBirthError,
    firstNameError,
    setFirstNameError,
    lastNameError,
    placeOfBirthError,
    setPlaceOfBirthError,
    setDateOfBirthError,
    setLastNameError,
    zipcodeError,
    setZipcodeError,
  }
}
