import React, { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'

import Residence from '../../../components/Residence'
import OrderDetails from '../../../components/Details'
import AddressAndTerms from '../../../components/AddressAndTerms'
import { ILangType } from '../../../../locales'
import { IOrder } from '../../../../api/order'
import { ICustomer } from '../../../../types/customer'
import useGlobalStyles from '../../../../hooks/useGlobalStyles'
import usePaymentFormStyles from '../../../components/usePaymentFormStyles'
import { ICountryState, ICountry } from '../../../../types/country'

interface IPayFirstStepProps {
  hasBilling: boolean
  setHasBilling(value: boolean): void
  billingAddress: any
  countriesData: ICountry[]
  statesData: ICountryState[]
  lang: ILangType
  detectedCountry?: string
  countryError?: string
  order: IOrder
  customer: ICustomer
  setConfirmFormChange(v: boolean): void
  addressAcceptFieldRef: any
  termsError?: string
  setTermsError(err: string): void
  setTermsAction(terms: any): void
  termsAcceptFieldRef?: any
  onProceed(countryCode: string, stateCode?: string): void
  setBillingData: any
  cityError?: string
  setCityError: any
  zipcodeError?: string
  setZipcodeError: any
  streetAddressError?: string
  setStreetAddressError: any
  dateOfBirthError?: string
  firstNameError?: string
  setFirstNameError(err?: string): void
  lastNameError?: string
  placeOfBirthError?: string
  setPlaceOfBirthError(err?: string): void
  setDateOfBirthError(err?: string): void
  setLastNameError(err?: string): void
}

const PayFirstStep: FC<IPayFirstStepProps> = ({
  hasBilling,
  setHasBilling,
  countriesData,
  statesData,
  lang,
  detectedCountry,
  countryError,
  order,
  customer,
  setConfirmFormChange,
  addressAcceptFieldRef,
  termsError,
  setTermsError,
  setTermsAction,
  termsAcceptFieldRef,
  onProceed,
  cityError,
  setCityError,
  zipcodeError,
  setZipcodeError,
  streetAddressError,
  setStreetAddressError,
  setBillingData,
  billingAddress,
  dateOfBirthError,
  firstNameError,
  setFirstNameError,
  lastNameError,
  placeOfBirthError,
  setPlaceOfBirthError,
  setDateOfBirthError,
  setLastNameError,
}) => {
  const { t } = useTranslation()

  const paymentFormClasses = usePaymentFormStyles()
  const globalClasses = useGlobalStyles()

  const [countryCode, setSelectedCountryCode] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(!countryCode)
  const [stateCode, setSelectedStateCode] = useState<string | undefined>(
    undefined
  )

  const handleProceed = () => {
    onProceed(countryCode, stateCode)
  }

  useEffect(() => {
    setSelectedStateCode(undefined)
    setBillingData({ address: '', city: '', zipCode: '' })
  }, [countryCode])

  return (
    <>
      <Residence
        order={order}
        setHasBilling={setHasBilling}
        billingAddress={billingAddress}
        lang={lang}
        countriesData={countriesData}
        statesData={statesData}
        countryCode={countryCode}
        stateCode={stateCode}
        setCountryCode={setSelectedCountryCode}
        setStateCode={setSelectedStateCode}
        detectedCountry={detectedCountry}
        countryError={countryError}
        btnDisabled={btnDisabled}
        setBtnDisabled={setBtnDisabled}
        setBillingData={setBillingData}
        setStreetAddressError={setStreetAddressError}
        cityError={cityError}
        setCityError={setCityError}
        setZipcodeError={setZipcodeError}
        streetAddressError={streetAddressError}
        zipcodeError={zipcodeError}
        dateOfBirthError={dateOfBirthError}
        firstNameError={firstNameError}
        setFirstNameError={setFirstNameError}
        lastNameError={lastNameError}
        placeOfBirthError={placeOfBirthError}
        setPlaceOfBirthError={setPlaceOfBirthError}
        setDateOfBirthError={setDateOfBirthError}
        setLastNameError={setLastNameError}
      />
      <OrderDetails order={order} styles={paymentFormClasses} />
      <AddressAndTerms
        customer={customer}
        setConfirmFormChange={setConfirmFormChange}
        addressAcceptField={addressAcceptFieldRef}
        termsError={termsError}
        setTermsError={setTermsError}
        setTermsAction={setTermsAction}
        termsAcceptField={termsAcceptFieldRef}
      />
      <div className={globalClasses.payButtonWrapper}>
        <Button
          className={clsx('customButton', globalClasses.button)}
          disabled={btnDisabled}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleProceed}
        >
          {t('Proceed')}
        </Button>
      </div>
    </>
  )
}

export default PayFirstStep
