import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { IOrder } from '@/api/order'
import { MIRROR } from '@/helpers/constants'
import { WalletVerificationStatus } from '@/types'
import useGlobalStyles from '@/hooks/useGlobalStyles'
import { useCustomerWalletVerify } from '@/api/customer'

import OrderDetails from '../Details'
import { FinalScreenIcon } from '../FinalScreen'
import usePaymentFormStyles from '../usePaymentFormStyles'
import { getSupportEmail } from '@/helpers/mirrorHelper'

interface IProps {
  order: IOrder
  onSuccess: () => void
  nextStep: (step?: string) => void
  setResultError: (error?: string) => void
  setPaymentError: (error: string) => void
  setIsNeedConfirmation: (confirm: boolean) => void
}

export default function WalletVerificationPending({
  order,
  nextStep,
  onSuccess,
  setResultError,
  setPaymentError,
  setIsNeedConfirmation,
}: IProps) {
  const { t } = useTranslation()

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const paymentFormClasses = usePaymentFormStyles()

  const [verifyWallet] = useCustomerWalletVerify((res: Record<string, any>) => {
    if (res?.success) {
      if (res?.data?.verificationStatus === WalletVerificationStatus.APPROVED) {
        setIsNeedConfirmation(false)
        onSuccess()
        return
      }

      if (res?.data?.verificationStatus === WalletVerificationStatus.REJECTED) {
        window.localStorage.setItem(
          'pe',
          JSON.stringify({ oid: order.id, e: 'KYT_REJECTED' })
        )
        setIsNeedConfirmation(false)
        setResultError('KYT_REJECTED')
        nextStep('error')
        return
      }

      return setTimeout(() => verifyWallet({ orderId: order.id }), 10000)
    } else {
      setIsNeedConfirmation(false)
      setPaymentError('somethingWentWrong')
    }
  })

  useEffect(() => {
    verifyWallet({ orderId: order.id })
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.result}>
        <FinalScreenIcon step="pending" />
      </div>
      <div className={classes.title}>
        <Typography
          variant="h5"
          align="center"
          component="div"
          color="textPrimary"
        >
          {t('walletVerificationPending.title')}
        </Typography>
      </div>

      <OrderDetails order={order} styles={paymentFormClasses} />
      <Link
        className={clsx(globalClasses.button, 'text')}
        component={Button}
        href={getSupportEmail(MIRROR)}
      >
        {t('Contact support')}
      </Link>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
    color: 'rgba(0,0,0,0.6)',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  result: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(4),
  },
}))
