import { IOrder } from '@/api/order'
import { isSf } from './constants'

interface IBackgroundCheckParams {
  order: IOrder
  apmName?: string
  isShowApmView: boolean
  isBackgroundShown?: boolean
}

export function getIsOBIBackground({
  order,
  apmName = '',
  isShowApmView,
  isBackgroundShown = false,
}: IBackgroundCheckParams) {
  return (
    order?.apm &&
    ['OBI', 'OBI_FT', 'OBI_TR'].includes(order.apm) &&
    (apmName === 'Bank Transfer' || apmName === 'OBI') &&
    isShowApmView &&
    !isBackgroundShown &&
    isSf
  )
}
