import { ICurrency } from '@/types/currency'
import { IOrder } from '@/api/order'
import { ICustomer } from '@/types/customer'
import { ISelectedApmInfo } from '@/types'

export const setExchangeRate = (data?: number) => ({
  type: 'SET_EXCHANGE_RATE',
  data,
})

export const setOrderDataRdx = (data?: IOrder) => ({
  type: 'SET_ORDER_DATA',
  data,
})

export const setCustomerAction = (data?: ICustomer) => ({
  type: 'SET_CUSTOMER',
  data,
})

export const setСonvertedApm = (data?: {
  selected?: string
  apmAmount?: number
}) => ({ type: 'SET_CONVERTED_APM', data })

export const updateCurrencies = (data?: {
  loading?: boolean
  data?: ICurrency[]
  map?: Record<string, ICurrency>
}) => ({ type: 'UPDATE_CURRENCIES', data })

export const setIsOBIBackgroundShown = (data: boolean) => ({
  type: 'SET_IS_OBI_BACKGROUND_SHOWN',
  data,
})

export const setApmDataRdx = (data?: ISelectedApmInfo) => ({
  type: 'SET_APM_DATA',
  data,
})
