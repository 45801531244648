import React, { useRef } from 'react'

import { useHistory } from 'react-router'

import clsx from 'clsx'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'

import useApplePay from '@/hooks/useApplePay'
import { trackClickGoogleOrApplePay } from '@/helpers/analytics'
import RiskDisclosureModal, {
  IRiskDisclosureModalHandles,
} from '@/components/RiskDisclosureModal'
import { useAcceptRiskDisclosure } from '@/api/private/account'

import { IApplePayButtonProps } from './MobilePay.types'
import ApplePayIcon from '../../../icons/applePay.svg'
import useGlobalStyles from '../../../hooks/useGlobalStyles'

const useStyles = makeStyles((theme) => ({
  applePayWrapper: {
    paddingBottom: theme.spacing(1.25),
  },
  applePay: {
    'background': `url(${ApplePayIcon}) no-repeat center #000`,
    'color': '#fff',
    '&.processing': {
      backgroundImage: 'none',
    },
    '&:disabled, &:hover': {
      backgroundColor: '#000',
    },
    '&:disabled': {
      opacity: 0.75,
    },
  },
}))

export default function ApplePay(props: IApplePayButtonProps) {
  const {
    onApplePayPayment,
    validateCountryAndTerms,
    validateBillingAddress,
    requestInProgress,
    order,
    setApplePayStarted,
    checkingKyc,
    kycRequired,
    setPaymentError,
    customer,
    initPaymentMobile,
    setCustomer,
    billing,
    saveBillingForApplePay,
  } = props

  const history = useHistory()
  const historyUnblockRef = useRef<null | (() => void)>(null)
  const riskDisclosureModalRef = useRef<IRiskDisclosureModalHandles>(null)
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const [acceptRiskDisclosure, acceptingRiskDisclosure] =
    useAcceptRiskDisclosure((res: Record<string, any>) => {
      if (res.success) {
        setCustomer({ ...customer, riskDisclosure: true })
        riskDisclosureModalRef.current?.hide()
      } else {
        setPaymentError('somethingWentWrong')
        riskDisclosureModalRef.current?.hide()
      }
    })

  const applePay = useApplePay({
    order,
    customer,
    initPayment: initPaymentMobile,
  })

  const handlePressPayment = () => {
    if (!validateBillingAddress(validateCountryAndTerms(true, true)[0])) return
    if (!validateCountryAndTerms(true, true)[0]) return

    if (!customer.riskDisclosure) {
      riskDisclosureModalRef.current?.show()
      return
    }

    setApplePayStarted()

    if (billing) {
      saveBillingForApplePay(() => {
        historyUnblockRef.current = history.block()
        applePay(setPaymentError, onApplePayPayment)
        historyUnblockRef.current && historyUnblockRef.current()
        trackClickGoogleOrApplePay('click_apple_pay', order, 'apple pay')
      })
    } else if (!kycRequired) {
      historyUnblockRef.current = history.block()
      applePay(setPaymentError, onApplePayPayment)
      historyUnblockRef.current && historyUnblockRef.current()
      trackClickGoogleOrApplePay('click_apple_pay', order, 'apple pay')
    }
  }

  return (
    <>
      <RiskDisclosureModal
        ref={riskDisclosureModalRef}
        onPressCancel={() => riskDisclosureModalRef.current?.hide()}
        onPressConfirm={() => acceptRiskDisclosure({})}
      />
      <div className={classes.applePayWrapper}>
        <Button
          id="apple-pay-button"
          onClick={handlePressPayment}
          className={clsx(globalClasses.button, classes.applePay)}
          variant="contained"
          disabled={requestInProgress || checkingKyc || acceptingRiskDisclosure}
        />
      </div>
    </>
  )
}
