import React from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Divider from '@material-ui/core/Divider'

import ApplePay from './ApplePay'
import GooglePay from './GooglePay'
import { IMobilePayProps } from './MobilePay.types'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  applePayWrapper: {
    paddingTop: theme.spacing(3),
  },
}))

export default function MobilePay({
  hasApplePay,
  hasGooglePay,
  order,
  initPayment,
  validateCountryAndTerms,
  validateBillingAddress,
  requestInProgress,
  isPayByCard,
  setApplePayStarted,
  applePayStarted,
  billing,
  saveBilling,
  onGooglePayPayment,
  setGooglePayStarted,
  googlePayStarted,
  checkingKyc,
  kycRequired,
  customer,
  paymentData,
  onApplePayPayment,
  setPaymentError,
  initPaymentMobile,
  setCustomer,
  saveBillingForApplePay,
}: IMobilePayProps) {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.root}>
        {hasApplePay && !googlePayStarted && (
          <ApplePay
            order={order}
            billing={billing}
            setCustomer={setCustomer}
            initPaymentMobile={initPaymentMobile}
            validateCountryAndTerms={validateCountryAndTerms}
            validateBillingAddress={validateBillingAddress}
            onApplePayPayment={onApplePayPayment}
            requestInProgress={requestInProgress}
            setApplePayStarted={setApplePayStarted}
            applePayStarted={applePayStarted}
            checkingKyc={checkingKyc}
            kycRequired={kycRequired}
            customer={customer}
            setPaymentError={setPaymentError}
            saveBillingForApplePay={saveBillingForApplePay}
          />
        )}
        {hasGooglePay && !applePayStarted && (
          <GooglePay
            order={order}
            paymentData={paymentData}
            initPayment={initPayment}
            validateCountryAndTerms={validateCountryAndTerms}
            validateBillingAddress={validateBillingAddress}
            onGooglePayPayment={onGooglePayPayment}
            requestInProgress={requestInProgress}
            isPayByCard={isPayByCard}
            setGooglePayStarted={setGooglePayStarted}
            googlePayStarted={googlePayStarted}
            billing={billing}
            saveBilling={saveBilling}
            checkingKyc={checkingKyc}
            kycRequired={kycRequired}
            customer={customer}
          />
        )}
        <Divider />
      </div>
    </div>
  )
}
