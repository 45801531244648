import { useEffect, useState } from 'react'

export default function useCopyQR() {
  const [isQRCopied, setIsQRCopied] = useState(false)

  useEffect(() => {
    const interval = setTimeout(() => setIsQRCopied(false), 500)

    return () => clearTimeout(interval)
  }, [isQRCopied])

  const handleCopy = () => {
    setIsQRCopied(true)
  }

  return { onCopy: handleCopy, isQRCopied }
}
