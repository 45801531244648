import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'

import { useTranslation } from 'react-i18next'

import CopyToClipboard from 'react-copy-to-clipboard'

import QRCode from 'react-qr-code'

import clsx from 'clsx'

import { Button } from '@material-ui/core'

import { IOrder } from '@/api/order'
import CloseIcon from '@/icons/close.svg'
import TimerIcon from '@/icons/timer.svg'
import useGlobalStyles from '@/hooks/useGlobalStyles'

import useTimer from './hooks/useTimer'
import useCopyQR from './hooks/useCopyQR'
import QRPaymentModalStyles from './QRPaymentModalStyles'

export interface IQRPaymentModalHandles {
  show: () => void
  hide: () => void
}

interface IQRPaymentModal {
  order: IOrder
  qrCode?: string

  nextStep: (error: string) => void
  setQRCode: (qrCode: string) => void
  setResultError: (error: undefined | string) => void
}

const QRPaymentModal = forwardRef(function QRPaymentModal(
  {
    order,
    qrCode = '',

    nextStep,
    setQRCode,
    setResultError,
  }: IQRPaymentModal,
  forwardedRef
) {
  const { t } = useTranslation()

  const classes = QRPaymentModalStyles()
  const globalClasses = useGlobalStyles()

  const [isOpenModal, setIsOpenModal] = useState(false)

  useImperativeHandle(forwardedRef, () => ({
    show: () => setIsOpenModal(true),
    hide: () => setIsOpenModal(false),
  }))

  const { onCopy, isQRCopied } = useCopyQR()
  const { timerMinutes, timerSeconds, totalSeconds } = useTimer({ qrCode })

  useEffect(() => {
    if (!totalSeconds && qrCode) {
      setQRCode('')
      window.localStorage.setItem(
        'pe',
        JSON.stringify({ oid: order?.id, e: 'ORDER_CANCEL' })
      )
      setResultError('ORDER_CANCEL')
      nextStep('error')
      return
    }
  }, [qrCode, totalSeconds])

  if (!isOpenModal) {
    return null
  }

  return (
    <div className={classes.modal}>
      <div className={classes.content}>
        <div className={classes.buttonCloseWrapper}>
          <button
            className={classes.buttonClose}
            onClick={() => setIsOpenModal(false)}
          >
            <img src={CloseIcon} alt="close icon" />
          </button>
        </div>
        <div className={classes.panel}>
          <div className={classes.id}>
            {t('Exchange order')} #{order.id}
          </div>
          <div className={classes.amount} translate="no">
            {`${order.paymentAmount + ' ' + order.paymentCurrency}`}
          </div>
        </div>
        <div className={classes.hint}>
          <p className={classes.text}>{t('qrModal.scanHint')}</p>
        </div>
        <div className={classes.qr}>
          <CopyToClipboard text={qrCode} onCopy={onCopy}>
            <QRCode value={qrCode} size={233} className={classes.qrImage} />
          </CopyToClipboard>
        </div>
        <div className={classes.timer}>
          <div className={classes.timerIcon}>
            <img src={TimerIcon} alt="timer icon" />
          </div>
          <div className={classes.time}>
            {timerMinutes}:{timerSeconds}
          </div>
        </div>
        <div>
          <CopyToClipboard text={qrCode} onCopy={onCopy}>
            <Button
              className={clsx(
                globalClasses.button,
                classes.button,
                isQRCopied && classes.copied
              )}
              variant="contained"
              color="primary"
              translate="no"
            >
              {isQRCopied ? t('qrModal.copied') : t('qrModal.copyButton')}
            </Button>
          </CopyToClipboard>
        </div>
        <div className={classes.hint}>
          <p className={classes.text}>{t('qrModal.copyHint')}</p>
        </div>
      </div>
    </div>
  )
})

export default QRPaymentModal
