import { makeStyles } from '@material-ui/core'

const QRPaymentModalStyles = makeStyles((theme) => ({
  root: {},
  modal: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    padding: '0px 8px',
    zIndex: 2,
  },
  content: {
    position: 'relative',
    borderRadius: theme.spacing(1),
    padding: '24px 32px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: '420px',
    width: '420px',
    boxShadow:
      '0 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.12), 0 16px 24px 0 rgba(0, 0, 0, 0.14)',
    background: '#FFF',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down(420)]: {
      padding: '24px 16px',
    },
  },
  panel: {
    marginBottom: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.87)',
  },
  id: {
    fontSize: '0.875rem',
    lineHeight: '150%',
    letterSpacing: '0.02em',
    marginBottom: '2px',
  },
  amount: {
    fontWeight: 500,
    fontSize: '1.25rem',
  },
  timer: {
    alignSelf: 'center',
    display: 'flex',
    gap: '4px',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: theme.spacing(1),
    padding: '4px 12px 4px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 'fit-content',
    marginBottom: theme.spacing(2),
  },
  timerIcon: {
    display: 'flex',
  },
  time: {
    fontWeight: 500,
    fontSize: '1rem',
  },
  hint: {
    borderRadius: theme.spacing(1),
    padding: '8px 12px',
    backgroundColor: '#F5F6F7',
    letterSpacing: '0.02em',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: '21px',
  },
  text: {
    margin: 0,
  },
  qr: {
    marginBottom: '21px',
  },
  button: {
    'background': '#32BCAD',
    '&:hover': {
      backgroundColor: '#2ca598',
    },
    'height': '46px',
    'lineHeight': '114%',
    'letterSpacing': '0.09em',
    'marginBottom': theme.spacing(2),
  },
  copied: {
    'backgroundColor': 'rgba(50, 188, 173, 0.8)',
    '&:hover': {
      backgroundColor: 'rgba(50, 188, 173, 0.8)',
    },
  },
  buttonClose: {
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
  },
  buttonCloseWrapper: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  qrImage: {
    cursor: 'pointer',
  },
}))

export default QRPaymentModalStyles
