import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'
import { Form } from 'react-final-form'
import { OnChange, OnBlur } from 'react-final-form-listeners'

import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import LockIcon from '@material-ui/icons/Lock'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import Link from '@material-ui/core/Link'

import WalletOwnerModal, {
  IWalletOwnerModalHandles,
} from '@/components/WalletOwnerModal'
import UDIcon from '@/icons/ud.svg'
import { MIRROR } from '@/helpers/constants'
import captchaRequest from '@/helpers/captcha'
import { useUDomain } from '@/hooks/useUDomain'
import { trackConfirmLogin, trackOnrampLogin } from '@/helpers/analytics'
import ResidenceRestricted from '@/views/Account/Profile/ResidenceRestricted'
import { getPrivacyPolicyLink, getTermsOfUseLink } from '@/helpers/mirrorHelper'

import KycRejected from './KycRejected'
import useFormStyles from './useFormStyles'
import ConfirmationCodeForm from './CodeForm'
import { IExchangeFormProps } from '../index'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import { Checkbox, Currency, Input } from '../../../components/Form'
import {
  composeValidators,
  isEmail,
  isWalletAddress,
  required,
  isNewXRPAddress,
} from '../../../validators'
import {
  IOrderAcceptReq,
  useAcceptOrder,
  OrderStatus,
} from '../../../api/order'
import ServerMessage, {
  TServerMessageType,
} from '../../../components/UI/ServerMessage'
import { qaAttr } from '../../../helpers/qaHelpers'
import { splitAddress } from '../../../helpers/address'
import TXLimitError from '../../../components/TxLimitsError'
import useEmailSuggest from '../../../hooks/useEmailSuggest'
import globalConfig from '../../../config/global.json'
import { setActiveForm } from '../../../store/actions/routing'
import { ActiveForm } from '../../../store/reducers/routing'
import { ICurrency } from '../../../types/currency'
import { currencyOptionMapper } from '../../../helpers/currency'
import Whisper from '../../../components/Whisper'
import ConfirmModal from '../../../components/ConfirmModal'
import { OrderExtendedType } from '../../../api/private/orders'
import { useCodeResend, useCustomerWallet } from '../../../api/customer'
import { INTERNAL_LINK_TARGET_BLANK } from '../../../helpers/internalTarget'
import CurrencyNameMapper from '../../../components/CurrencyNameMapper/CurrencyNameMapper'

interface IProps extends IExchangeFormProps {
  currencies: any[]
  currenciesLoading: boolean
  authToken?: string
  extTimestamp?: number
  extPublicKey?: string
  extSignature?: string
  extAlg?: string
}

export default function AuthForm({
  setStep,
  setEmail,
  email,
  order,
  t,
  setCustomer,
  customer,
  loadOrder,
  setAddress,
  ui,
  language,
  currencies,
  currenciesLoading,
  setOrder,
  authToken,
  setProcessingError,
  extTimestamp,
  extPublicKey,
  extSignature,
  extAlg,
}: IProps) {
  const {
    form: {
      authorization: { fields },
      buttonName,
      buttonColor,
      borderRadius,
    },
    global: {
      palette: { border },
      logo,
    },
  } = ui

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let setDisableEmailChanging = (v1?: any, v2?: any, v3?: any) => {}
  const history = useHistory()
  const dispatch = useDispatch()
  const formClasses = useFormStyles({ buttonColor, borderRadius, border })
  const globalClasses = useGlobalStyles()
  const [confirm, setConfirm] = useState(false)
  const [emailSuggestion, checkEmail] = useEmailSuggest()
  const [customerVerified, setCustomerVerified] = useState(false)
  const [saveCustomerWallet, setSaveCustomerWallet] = useState<
    undefined | any
  >()
  const [authRequestData, setAuthRequestData] =
    useState<IOrderAcceptReq | null>(null)
  const addressField = useRef(null)
  const emailField = useRef(null)
  const termsAcceptField = useRef(null)
  const policyAcceptField = useRef(null)
  const walletOwnerModalRef = useRef<IWalletOwnerModalHandles>(null)
  const [termsAction, setTermsAction] = useState<undefined | any>()
  const [policyAction, setPolicyAction] = useState<undefined | any>()
  const [hideTerms, setHideTerms] = useState(false)
  const [lockAddress, setLockAddress] = useState(!!order.address)
  const {
    isValidUDomain, checkIsValidUDomain, resolveAddressFromUD, uDomainSupportError, uDomainResolveError,
  } = useUDomain();

  const [isMemoConfirmOpen, setIsMemoConfirmOpen] = useState(false)
  const isPayout = order.type === OrderExtendedType.WALLET_TO_FIAT

  const [initVals, setInitVals] = useState<any>(() => {
    const initialData: Record<string, any> = {
      email: email || order.acceptedByEmail || '',
      terms: false,
      policy: false,
      ...order,
      saveCustomerWallet,
      disableEmailChanging:
        !!email ||
        !!order.acceptedByEmail ||
        (fields && fields.email && fields.email.disable) ||
        customerVerified,
    }

    if (order.unDomain) {
      initialData.address = order.unDomain
    } else {
      const [addressPart, memoTagPart] = splitAddress(order.address)
      if (memoTagPart) {
        initialData.address = addressPart
        initialData.memoTag = memoTagPart
      }
    }

    return initialData
  })

  const [walletCreds, setWalletCreds] = useState({
    timestamp: new Date().getTime(),
    publicKey: '',
    signature: '',
    alg: '',
  })

  const [checkCustomer] = useCustomerWallet((res: any) => {
    if (res.success) {
      setCustomerVerified(true)
      if (res.data.termsAccepted && res.data.privacyPolicyAccepted) {
        setHideTerms(true)
      }
      if (!email && res.data.email) {
        setEmail(res.data.email)
        setDisableEmailChanging(true)
      }
    }
  })

  const {
    accept,
    limitError,
    errorType: serverErrorType,
    error: serverError,
    kycRejected,
    countryNotSupported,
  } = useAcceptOrder({
    order,
    customer,
    setOrder: (o) => (setOrder ? setOrder(o) : null),
    setCustomer: (c) => (setCustomer ? setCustomer(c) : null),
    loadOrder,
    setConfirm,
    authToken,
    setProcessingError,
    navigateOnSuccess: customerVerified
      ? (status, id) => {
          history.push(
            `/order/${id}/${
              status === OrderStatus.VERIFICATION ? 'verification' : 'card'
            }${window.location.search}`
          )
        }
      : undefined,
  })

  const [resendCode] = useCodeResend(() => {
    setConfirm(true)
  })

  useEffect(() => {
    walletCreds.publicKey &&
      walletCreds.signature &&
      walletCreds.timestamp &&
      checkCustomer({ ...walletCreds })
  }, [walletCreds])

  useEffect(() => {
    trackOnrampLogin('view_order_details', order, 'onramp_login')
  }, [])

  useEffect(() => {
    let timestampReq = 0
    let publicKeyReq = ''
    let signatureReq = ''

    if (extTimestamp && extPublicKey && extSignature) {
      timestampReq = extTimestamp
      publicKeyReq = extPublicKey && extPublicKey.replace(/ /g, '') //Hack for Android React Native
      signatureReq = extSignature && extSignature.replace(/ /g, '') //Hack for Android React Native
    }

    setWalletCreds({
      timestamp: timestampReq,
      publicKey: publicKeyReq,
      signature: signatureReq,
      alg: extAlg || 'ED25519',
    })
  }, [extTimestamp, extPublicKey, extSignature, extAlg])

  // if order was processed - send auth code and set to code form
  useEffect(() => {
    if (!['NEW', 'ACCEPTED'].includes(order.status) && order.acceptedByEmail) {
      captchaRequest('/api/widget/v1/customer/code', resendCode,{ id: order.id, email: order.acceptedByEmail })
    }
  }, [order])

  // resolve address from UD
  useEffect(() => {
    if (isValidUDomain && order.currency) {
      resolveAddressFromUD(order.currency);
    }
  }, [isValidUDomain, order.currency])

  if (kycRejected) {
    return (
      <KycRejected
        classes={formClasses}
        t={t}
        order={order}
        type={kycRejected ? 'kyc' : 'walletAddress'}
      />
    )
  }

  if (countryNotSupported) {
    return <ResidenceRestricted country={countryNotSupported} order={order} />
  }

  if (confirm) {
    dispatch(setActiveForm(ActiveForm.OTP))
    return (
      <ConfirmationCodeForm
        order={order}
        email={email}
        t={t}
        setCustomer={setCustomer}
        setStep={setStep}
        customer={customer}
        formClasses={formClasses}
        saveCustomerWallet={saveCustomerWallet}
        extTimestamp={walletCreds.timestamp}
        extPublicKey={walletCreds.publicKey}
        extSignature={walletCreds.signature}
        extAlg={walletCreds.alg}
      />
    )
  }

  const externalMerchant = !order.legal || order.legal.name !== 'Utorg'
  const isTopUp =
    order.type === OrderExtendedType.CRYPTO_TO_WALLET ||
    order.currency === order.paymentCurrency
  const isCBC =
    externalMerchant && order.legal && order.legal.name === 'GameTwist'

  const onSubmit = (values: any) => {
    const data: IOrderAcceptReq = {
      email: values.email,
      id: order.id,
      currency: isPayout ? values.currency : values.paymentCurrency,
      address: order.address ? undefined : values.address.trim(),
      authToken,
    }
    setSaveCustomerWallet(values.saveCustomerWallet)

    const currency = (
      values.currency
        ? currencies.find((c) => c.currency === values.currency)
        : {}
    ) as ICurrency

    if (
      data.address &&
      currency.allowTag &&
      !values.memoTag &&
      !isNewXRPAddress(values.address)
    ) {
      return setIsMemoConfirmOpen(true)
    }

    if (currency.allowTag && values.memoTag && data.address) {
      const { address } = data
      data.address = `${address}:${values.memoTag}`
    }

    if (!authRequestData) {
      walletOwnerModalRef.current?.show()
      setAuthRequestData(data)
      return
    }

    if (!customerVerified && order.status !== 'NEW') {
      return captchaRequest('/api/widget/v1/customer/code', resendCode,{ ...data, orderId: order.id })
    }

    return captchaRequest('/api/widget/v1/order/accept', accept, { ...data, ...walletCreds })
  }

  return (
    <div>
      <ConfirmModal
        isShown={isMemoConfirmOpen}
        onConfirm={() => {
          setIsMemoConfirmOpen(false)
          walletOwnerModalRef.current?.show()
        }}
        onCancel={() => setIsMemoConfirmOpen(false)}
        title={t('memo.confirmTitle')}
        text={t('memo.confirmText')}
        caption={t('memo.confirmCaption')}
        confirmBtnText={t('Confirm')}
        returnBtnText={t('Return')}
      />
      <WalletOwnerModal
        ref={walletOwnerModalRef}
        wallet={initVals?.address}
        onPressCancel={() => {
          setAuthRequestData(null)
          walletOwnerModalRef.current?.hide()
        }}
        onPressConfirm={() => {
          if (!customerVerified && order.status !== 'NEW') {
            return captchaRequest('/api/widget/v1/customer/code', resendCode, {
              ...authRequestData,
              orderId: order.id,
            })
          }

          captchaRequest('/api/widget/v1/order/accept', accept, {
            ...authRequestData,
            ...walletCreds,
          })
        }}
      />
      <Form
        onSubmit={onSubmit}
        mutators={{
          setEmail: (args, state, { changeValue }) => {
            changeValue(state, 'email', () => args[0])
          },
          setDisableEmailChanging: (args, state, { changeValue }) => {
            changeValue(state, 'disableEmailChanging', () => args[0])
          },
        }}
        initialValues={initVals}
        render={({ handleSubmit, submitting, form, values }) => {
          const currency = (
            values.currency
              ? currencies.find((c) => c.currency === values.currency)
              : {}
          ) as ICurrency
          setEmail = form.mutators.setEmail
          setDisableEmailChanging = form.mutators.setDisableEmailChanging
          return (
            <div className={formClasses.root}>
              {limitError.error.length > 0 ? (
                <TXLimitError error={limitError} failUrl={order.failUrl} />
              ) : ['NEW', 'ACCEPTED'].includes(order.status) ? (
                <form
                  onSubmit={(e) => {
                    // @ts-ignore
                    handleSubmit(e)
                    const { errors, touched, hasValidationErrors } =
                      form.getState()
                    // set focus to error field
                    if (hasValidationErrors && touched) {
                      const errFields = Object.keys(errors)
                      for (let k = 0, l = errFields.length; k < l; k++) {
                        const f = errFields[k]
                        if (touched[f]) {
                          if (f === 'address') {
                            // @ts-ignore
                            addressField.current.focus()
                            break
                          }
                          if (f === 'email') {
                            // @ts-ignore
                            emailField.current.focus()
                            break
                          }
                          if (f === 'terms') {
                            // @ts-ignore
                            termsAcceptField.current.focus()
                            if (termsAction) {
                              termsAction.focusVisible()
                            }
                            break
                          }
                          if (f === 'policy') {
                            // @ts-ignore
                            policyAcceptField.current.focus()
                            if (policyAction) {
                              policyAction.focusVisible()
                            }
                            break
                          }
                        }
                      }
                    }
                    trackConfirmLogin(
                      'click_proceed',
                      order,
                      'Auth form',
                      'onramp_login'
                    )
                  }}
                >
                  {serverError.length > 0 && (
                    <ServerMessage
                      type={serverErrorType as TServerMessageType}
                      text={serverError}
                    />
                  )}
                  {externalMerchant ? (
                    <div>
                      <Currency
                        inputName="paymentAmount"
                        selectName="paymentCurrency"
                        currencies={currencies
                          .filter(
                            (c: ICurrency) =>
                              c.enabled &&
                              (order.type ===
                                OrderExtendedType.CRYPTO_TO_WALLET ||
                                c.type === 'FIAT')
                          )
                          .map(currencyOptionMapper)}
                        label={
                          fields &&
                          fields.paymentCurrency.title[language].length
                            ? fields.paymentCurrency.title[language]
                            : t('labels.Sell')
                        }
                        styles={{ border, borderRadius }}
                        currenciesLoading={currenciesLoading}
                        locked={order.paymentCurrencyLocked}
                        className={
                          values.paymentAmount &&
                          values.paymentAmount.toString().length > 10
                            ? 'text-small'
                            : ''
                        }
                      />
                      {!isTopUp && (
                        <Typography
                          variant="body2"
                          className={formClasses.amountBottom}
                          translate="no"
                        >
                          ≈{order.amount}{' '}
                          <CurrencyNameMapper
                            currency={isCBC ? 'CBC' : order.currency}
                          />
                        </Typography>
                      )}
                      <OnChange name="paymentCurrency">
                        {(currency: string) => {
                          if (order.id && loadOrder) {
                            loadOrder({ id: order.id, currency })
                          }
                        }}
                      </OnChange>
                    </div>
                  ) : (
                    <div className={formClasses.row}>
                      {fields && fields.paymentCurrency.show && (
                        <Currency
                          inputName="paymentAmount"
                          selectName="paymentCurrency"
                          currencies={currencies
                            .filter(
                              (c: ICurrency) =>
                                c.enabled &&
                                (order.type ===
                                  OrderExtendedType.CRYPTO_TO_WALLET ||
                                  c.type === 'FIAT')
                            )
                            .map(currencyOptionMapper)}
                          label={
                            fields.paymentCurrency.title[language].length
                              ? fields.paymentCurrency.title[language]
                              : t('labels.Sell')
                          }
                          styles={{ border, borderRadius }}
                          currenciesLoading={currenciesLoading}
                          locked={order.paymentCurrencyLocked}
                          className={
                            values.paymentAmount &&
                            values.paymentAmount.toString().length > 10
                              ? 'text-small'
                              : ''
                          }
                        />
                      )}
                      <OnChange name="paymentCurrency">
                        {(currency: string) => {
                          if (order.id && loadOrder) {
                            loadOrder({ id: order.id, currency })
                          }
                        }}
                      </OnChange>
                      {order.currency !== order.paymentCurrency &&
                        fields &&
                        fields.currency.show && (
                          <Currency
                            inputName="amount"
                            selectName="currency"
                            currencies={currencies
                              .filter(
                                (c: ICurrency) =>
                                  c.type === 'CRYPTO' && c.enabled
                              )
                              .map(currencyOptionMapper)}
                            label={
                              fields.currency.title[language].length
                                ? fields.currency.title[language]
                                : t('labels.Buy')
                            }
                            styles={{ border, borderRadius }}
                            currenciesLoading={currenciesLoading}
                            className={
                              values.amount &&
                              values.amount.toString().length > 10
                                ? 'text-small'
                                : ''
                            }
                            isCBC={isCBC}
                          />
                        )}
                    </div>
                  )}
                  {!isPayout && (
                    <div>
                      <Input
                        name="address"
                        type="text"
                        label={
                          fields && fields.walletAddress.title[language].length
                            ? fields.walletAddress.title[language]
                            : t('labels.Wallet address')
                        }
                        fullWidth
                        validate={composeValidators(
                          required,
                          (v: string) => isWalletAddress(v, currency.addressValidator as string),
                        )}
                        inputRef={addressField}
                        disabled={lockAddress}
                        InputProps={{
                          endAdornment: externalMerchant ? (
                            <InputAdornment position="end">
                              <LockIcon />
                            </InputAdornment>
                          ) : <InputAdornment position="end"><img src={UDIcon} width={20} /></InputAdornment>,
                        }}
                        errorText={uDomainSupportError || uDomainResolveError}
                      />
                      <OnChange name="address">
                        {(v: string) => {
                          checkIsValidUDomain(v)
                          setAddress(v)
                        }}
                      </OnChange>
                    </div>
                  )}
                  {currency?.allowTag && !isNewXRPAddress(values.address) && (
                    <div>
                      <Input
                        name="memoTag"
                        type="memoTag"
                        label={t('memo.label')}
                        fullWidth
                        disabled={lockAddress}
                        inputProps={{
                          spellCheck: false,
                        }}
                        InputProps={{
                          endAdornment: externalMerchant ? (
                            <InputAdornment position="end">
                              <LockIcon />
                            </InputAdornment>
                          ) : undefined,
                        }}
                      />
                      <Whisper
                        text={t('memo.whatIsText')}
                        title={t('memo.whatIsLabel')}
                      >
                        {t('memo.whatIsLabel')}
                      </Whisper>
                    </div>
                  )}
                  {(!order.acceptedByEmail ||
                    (fields && fields.email && fields.email.show)) && (
                    <div style={{ position: 'relative' }}>
                      <Input
                        name="email"
                        type="email"
                        label={t('labels.Email')}
                        fullWidth
                        validate={composeValidators(required, isEmail)}
                        disabled={values.disableEmailChanging}
                        autoComplete="new-password"
                        inputRef={emailField}
                        inputProps={{
                          ...qaAttr('email-input'),
                          spellCheck: false,
                        }}
                        InputProps={{
                          endAdornment: values.disableEmailChanging && (
                            <InputAdornment position="end">
                              <div
                                className={formClasses.emailChangeBtn}
                                onClick={() => {
                                  setCustomerVerified(false)
                                  setDisableEmailChanging(false)
                                }}
                              >
                                Change
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {!values.disableEmailChanging &&
                        emailSuggestion.length > 0 && (
                          <FormHelperText variant="filled">
                            {t('Do you mean')}{' '}
                            <Link
                              onClick={() =>
                                form.mutators.setEmail(emailSuggestion)
                              }
                              className={formClasses.link}
                            >
                              {emailSuggestion}
                            </Link>
                            ?
                          </FormHelperText>
                        )}
                      <OnChange name="email">
                        {(v: string) => {
                          setEmail(v)
                          if (v && !isEmail(v)) {
                            trackOnrampLogin(
                              'add_email_success',
                              order,
                              'onramp_login'
                            )
                          }
                          if (globalConfig.useEmailSuggest) {
                            checkEmail(form.getFieldState('email'))
                          }
                        }}
                      </OnChange>
                      <OnBlur name="email">
                        {() => {
                          if (globalConfig.useEmailSuggest) {
                            checkEmail(form.getFieldState('email'))
                          }
                        }}
                      </OnBlur>
                    </div>
                  )}
                  <div>
                    {!hideTerms && (
                      <>
                        <div className={globalClasses.checkBox}>
                          <Checkbox
                            {...qaAttr('terms-checkbox')}
                            label={
                              <Typography
                                variant="body2"
                                component="span"
                                className={globalClasses.checkBoxText}
                              >
                                {t('agreeToTerms')}{' '}
                                <Link
                                  href={getTermsOfUseLink(MIRROR)}
                                  target={INTERNAL_LINK_TARGET_BLANK}
                                >
                                  {t('Terms of use')}
                                </Link>
                              </Typography>
                            }
                            name="terms"
                            validate={required}
                            inputRef={termsAcceptField}
                            setAction={setTermsAction}
                          />
                        </div>
                        <div className={globalClasses.checkBox}>
                          <Checkbox
                            {...qaAttr('terms-checkbox')}
                            label={
                              <Typography
                                variant="body2"
                                component="span"
                                className={globalClasses.checkBoxText}
                              >
                                <Trans i18nKey="agreeWithPolicy">
                                  I agree with the{' '}
                                  <Link
                                    href={getPrivacyPolicyLink(MIRROR)}
                                    target={INTERNAL_LINK_TARGET_BLANK}
                                  >
                                    Privacy Policy
                                  </Link>{' '}
                                  and how my data is collected, controlled, and
                                  processed
                                </Trans>
                              </Typography>
                            }
                            name="policy"
                            validate={required}
                            inputRef={policyAcceptField}
                            setAction={setPolicyAction}
                          />
                        </div>
                      </>
                    )}
                    {!customerVerified &&
                      extTimestamp &&
                      extPublicKey &&
                      extSignature && (
                        <div className={globalClasses.checkBox}>
                          <Checkbox
                            label={
                              <Typography
                                variant="body2"
                                component="span"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                {t('rememberEmail')}
                              </Typography>
                            }
                            name="saveCustomerWallet"
                          />
                        </div>
                      )}
                  </div>

                  <div className={globalClasses.payButtonWrapper}>
                    <Button
                      {...qaAttr('proceed-btn')}
                      className={clsx('customButton', globalClasses.button)}
                      disabled={submitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {buttonName[language] || t('Proceed')}
                    </Button>
                  </div>
                </form>
              ) : (
                serverError.length > 0 && (
                  <ServerMessage
                    type={serverErrorType as TServerMessageType}
                    text={serverError}
                  />
                )
              )}
            </div>
          )
        }}
      />
    </div>
  )
}
