import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import {
  Dialog,
  Typography,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { DatePicker } from '@material-ui/pickers'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'

import { ICountry } from '@/types/country'
import { DEFAULT_BILLING_COUNTRY } from '@/constants'
import { usePopupSelectStyles } from '@/views/components/Residence'
import { ICustomer, ICustomerBillingAddress } from '@/types/customer'
import { PaymentFormClasses } from '@/views/components/usePaymentFormStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  field: {
    '& .MuiFilledInput-input': {
      paddingTop: '11px',
      paddingBottom: '11px',
    },
    '& .MuiInputBase-input::placeholder': {
      opacity: 0.8,
    },
  },
  inputAdornment: {
    paddingLeft: theme.spacing(2),
  },
}))

interface IProps {
  cityError?: string
  customer?: ICustomer
  zipcodeError?: string
  countries?: ICountry[]
  lastNameError?: string
  firstNameError?: string
  dateOfBirthError?: string
  placeOfBirthError?: string
  streetAddressError?: string
  classes: PaymentFormClasses

  setLastNameError(err?: string): void
  setFirstNameError(err?: string): void
  setCityError: (error?: string) => void
  setDateOfBirthError(err?: string): void
  setPlaceOfBirthError(err?: string): void
  setZipcodeError: (error?: string) => void
  setStreetAddressError: (error?: string) => void
  setBillingData: (billingData: ICustomerBillingAddress) => void
}

export default function BillingFields({
  classes,
  customer,
  cityError,
  countries,
  zipcodeError,
  lastNameError,
  firstNameError,
  dateOfBirthError,
  placeOfBirthError,
  streetAddressError,

  setCityError,
  setBillingData,
  setZipcodeError,
  setLastNameError,
  setFirstNameError,
  setPlaceOfBirthError,
  setDateOfBirthError,
  setStreetAddressError,
}: IProps) {
  const { t } = useTranslation()

  const localClasses = useStyles()
  const popupSelectStylesClasses = usePopupSelectStyles()

  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<Date | null>(
    null
  )
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false)
  const [searchCountry, setSearchCountry] = useState<string | undefined>()
  const [selectedCountry, setSelectedCountry] = useState<ICountry>(
    DEFAULT_BILLING_COUNTRY
  )
  const countryModalHeight =
    (countries && countries?.length > 0 ? countries.length : 0) * 48 + 155

  const handleDateOfBirthChange = (date: MaterialUiPickersDate | null) => {
    if (!date) {
      return
    }

    setSelectedDateOfBirth(date)
    setDateOfBirthError(undefined)
    setBillingData({ dateOfBirth: String(date.valueOf()) })
    setIsPickerOpen(false)
  }

  function closeCountryModal() {
    setSearchCountry(undefined)
    setIsCountryModalOpen(false)
  }

  return (
    <div className={localClasses.root}>
      {isCountryModalOpen && countries && countries.length > 0 && (
        <Dialog open onClose={closeCountryModal}>
          <div
            className={classes.dialog}
            style={{
              height: countryModalHeight,
            }}
          >
            <div className={classes.dialogHeader}>
              <Typography component="div" className={classes.dialogTitle}>
                {t('billingAddress.selectPlaceOfBirth')}
              </Typography>
              <TextField
                fullWidth
                className={classes.field}
                placeholder={`${t('Search')}...`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={localClasses.inputAdornment}
                    >
                      <SearchIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchCountry(e.target.value)}
              />
            </div>
            <div className={clsx(classes.dialogList, 'apms')}>
              {countries
                .filter((country) =>
                  searchCountry?.length
                    ? country.name
                        .toLowerCase()
                        .includes(searchCountry.toLowerCase())
                    : true
                )
                .map((country) => (
                  <div
                    className={clsx(popupSelectStylesClasses.option, {
                      active: selectedCountry.code === country.code,
                    })}
                    onClick={() => {
                      setBillingData({ placeOfBirth: country.code })
                      setPlaceOfBirthError(undefined)
                      setSelectedCountry(country)
                      setIsCountryModalOpen(false)
                    }}
                    key={country.code}
                  >
                    <div className="details">
                      <img
                        src={`/img/flags/icon-${country.code}.svg`}
                        alt={country.name}
                      />
                      <Typography variant="body2">
                        {country.name}&nbsp;
                      </Typography>
                    </div>
                    <div className="details">
                      {selectedCountry.code === country.code && (
                        <CheckIcon color="inherit" className="check" />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Dialog>
      )}
      <div className={localClasses.title}>{t('billingAddress.title')}</div>
      <div className={classes.row}>
        <TextField
          name="firstName"
          variant="filled"
          id="billing-firstName"
          error={!!firstNameError}
          className={classes.field}
          helperText={firstNameError}
          label={t('labels.First name')}
          disabled={!!customer?.billingAddress?.firstName}
          value={customer?.billingAddress?.firstName || undefined}
          onChange={(e) => {
            const firstName = e.target.value
            setBillingData({ firstName })
            if (!firstName || !firstName.length) {
              setFirstNameError(t('validation.Required field'))
            } else {
              setFirstNameError(undefined)
            }
          }}
        />
        <TextField
          name="lastName"
          variant="filled"
          id="billing-lastName"
          error={!!lastNameError}
          className={classes.field}
          helperText={lastNameError}
          label={t('labels.Last name')}
          disabled={!!customer?.billingAddress?.lastName}
          value={customer?.billingAddress?.lastName || undefined}
          onChange={(e) => {
            const lastName = e.target.value
            setBillingData({ lastName })
            if (!lastName || !lastName.length) {
              setLastNameError(t('validation.Required field'))
            } else {
              setLastNameError(undefined)
            }
          }}
        />
      </div>
      <div className={classes.row}>
        <TextField
          variant="filled"
          name="middleName"
          id="billing-middleName"
          className={classes.field}
          label={t('labels.middleName')}
          onChange={(e) => setBillingData({ middleName: e.target.value })}
        />
      </div>
      {!customer?.billingAddress?.placeOfBirth && (
        <>
          <div className={classes.blockTitle}>
            {t('billingAddress.placeOfBirth')}
          </div>
          <div className={classes.row}>
            <TextField
              variant="filled"
              name="placeOfBirth"
              error={!!placeOfBirthError}
              value={selectedCountry.name}
              helperText={placeOfBirthError}
              onClick={() => setIsCountryModalOpen(true)}
              className={clsx(classes.field, localClasses.field)}
              placeholder={t('billingAddress.selectPlaceOfBirth')}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton onClick={() => setIsCountryModalOpen(true)}>
                    <ExpandMoreIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
        </>
      )}
      {!customer?.billingAddress?.dateOfBirth && (
        <>
          <div className={classes.blockTitle}>
            {t('billingAddress.dateOfBirth')}
          </div>
          <div className={classes.row}>
            <TextField
              hiddenLabel
              variant="filled"
              error={!!dateOfBirthError}
              helperText={dateOfBirthError}
              onClick={() => setIsPickerOpen(true)}
              className={clsx(classes.field, localClasses.field)}
              placeholder={t('billingAddress.dateOfBirthPlaceholder')}
              value={
                selectedDateOfBirth
                  ? selectedDateOfBirth.toLocaleDateString('ru-RU', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                  : ''
              }
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton onClick={() => setIsPickerOpen(true)}>
                    <CalendarTodayOutlinedIcon />
                  </IconButton>
                ),
              }}
            />
            <DatePicker
              open={isPickerOpen}
              value={selectedDateOfBirth}
              TextFieldComponent={() => null}
              onChange={handleDateOfBirthChange}
              onClose={() => setIsPickerOpen(false)}
            />
          </div>
        </>
      )}
      <div className={classes.blockTitle}>
        {t('billingAddress.fullAddress')}
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          name="city"
          variant="filled"
          label={t('labels.city')}
          onChange={(e) => {
            const city = e.target.value
            setBillingData({ city })
            if (!city || !city.length) {
              setCityError(t('validation.Required field'))
            } else {
              setCityError(undefined)
            }
          }}
          error={!!cityError}
          helperText={cityError}
          id="billing-city"
        />
        <TextField
          className={classes.field}
          name="zip"
          variant="filled"
          label={t('labels.zip')}
          onChange={(e) => {
            const zipCode = e.target.value
            setBillingData({ zipCode })
            if (!zipCode || !zipCode.length) {
              setZipcodeError(t('validation.Required field'))
            } else {
              setZipcodeError(undefined)
            }
          }}
          error={!!zipcodeError}
          helperText={zipcodeError}
          id="billing-zipcode"
        />
      </div>
      <div className={classes.row}>
        <TextField
          className={classes.field}
          name="streetAddress"
          variant="filled"
          label={t('labels.address')}
          onChange={(e) => {
            const address = e.target.value
            setBillingData({ address })
            if (!address || !address.length) {
              setStreetAddressError(t('validation.Required field'))
            } else {
              setStreetAddressError(undefined)
            }
          }}
          error={!!streetAddressError}
          helperText={streetAddressError}
          id="billing-address"
        />
      </div>
    </div>
  )
}
