import { ICustomer } from '@/types/customer'

export const getIsBillingAddressFilled = (customer?: ICustomer) => {
  if (!customer || !customer?.billingAddress) {
    return false
  }
  return (
    customer.billingAddress?.address &&
    customer.billingAddress?.city &&
    customer.billingAddress?.dateOfBirth &&
    customer.billingAddress?.firstName &&
    customer.billingAddress?.lastName &&
    customer.billingAddress?.zipCode &&
    customer.billingAddress?.placeOfBirth
  )
}
